"use client";
import Image from 'next/image';
import Link from 'next/link';
import './navbar.css';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import OpenBtnNav from './OpenBtnNav';
import CloseBtnNav from './CloseBtnNav';
import { useState, useEffect, useCallback } from 'react';
import LinkArrow from './LinkArrow';
import { UserInfo } from '@/app/api/ApiWord';


interface UserData {
  email: string;
  id: number;
  img: string;
  name: string;
  nickname: string;
  message?: string;
}

export default function Navigation() {

 


  const pathname = usePathname();
  const { data: session } = useSession();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const fetchData = useCallback(async () => {
    try {
      const data = await UserInfo();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem('jwt_token')) {
      fetchData();

      const handleStorageChange = () => {
        fetchData();
      };

      window.addEventListener('storage', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, [fetchData, session]);


  useEffect(() => {
    const fetchAndCheckCookies = async () => {
      const authNext = document.cookie.split('; ').find(row => row.startsWith('auth-next'));
      const authToken = document.cookie.split('; ').find(row => row.startsWith('auth_token'));

      if (
        pathname !== '/api/auth/signin' &&
        pathname !== '/signup' &&
        pathname !== '/signin' &&
        (authNext || authToken)
      ) {
        fetchData();
      }
    };

    fetchAndCheckCookies();

    window.addEventListener('storage', fetchAndCheckCookies);

    return () => {
      window.removeEventListener('storage', fetchAndCheckCookies);
    };
  }, [pathname, fetchData, session]); 


  

  

  return (
    <article className='navbar'>
      <nav className='navigation'>
        <div className='navigationLeft'>
          <Link href="/">
            <Image src="/logo_footer.svg" width={105} height={65} alt="logo" />
          </Link>
          <div className='navigationLink'>
            <span className='linkPage'>
              <Link href="/" className={pathname === "/" ? "activeLink" : ""}>
                <span>Home</span>
              </Link>
            </span>
            <span className='linkPage'>
              <Link href="/random-word" className={pathname === "/random-word" ? "activeLink" : ""}>
                <span>Random Word</span>
              </Link>
            </span>
            <span className='linkPage'>
              <Link href="/categories" className={pathname === "/categories" ? "activeLink" : ""}>
                <span>Categories</span>
              </Link>
            </span>
            <span className='linkPage'>
              <Link href="/about-us" className={pathname === "/about-us" ? "activeLink" : ""}>
                <span>About Us</span>
              </Link>
            </span>
          </div>
        </div>
        {userData ? (
          <div className='navigationRight'>
            <Link href="/profile">
              <span className="authInfo">
                {userData.img && <img src={userData.img} alt="User Image" />}
                <p>{userData.name}</p>
              </span>
            </Link>
          </div>
        ) : (
          <div className='navigationRight'>
            <span className='btnSignUp'><Link href="/signup">Sign Up</Link></span>
            <span className='btnSignIn'><Link href="/api/auth/signin">Sign In</Link></span>
          </div>
        )}
        <div className="navMobile">
          <div className="openNav" onClick={openModal}><OpenBtnNav /></div>
          {isModalOpen && 
            <div className="overlay">
              <div className="modal">
                <div className="closeBtn">
                  <div onClick={closeModal}><CloseBtnNav /></div>
                </div>
                <div className="mobileLink">
                  <Link href="/">
                    <div className="mobLinkNav" onClick={closeModal}>
                      <p>Home</p>
                      <LinkArrow />
                    </div>
                  </Link> 
                  <Link href="/random-word">
                    <div className="mobLinkNav" onClick={closeModal}>
                      <p>Random Word</p>
                      <LinkArrow />
                    </div>
                  </Link>
                  <Link href="/categories">
                    <div className="mobLinkNav" onClick={closeModal}>
                      <p>Categories</p>
                      <LinkArrow />
                    </div>
                  </Link>
                  <Link href="/about-us">
                    <div className="mobLinkNav" onClick={closeModal}>
                      <p>About Us</p>
                      <LinkArrow />
                    </div>
                  </Link>
                </div>
                {userData ? (
                  <div className='mobNavLogo' onClick={closeModal}>
                    <Link href="/profile">
                      <span className="authInfo">
                        {userData.img && <img src={userData.img} alt="User Image" />}
                        <p>{userData.name}</p>
                      </span>
                    </Link>
                  </div>
                ) : (
                  <div className="mobBtn">
                    <div className="mobBtnSignUp" onClick={closeModal}>
                      <Link href="/signup">Sign Up</Link>
                    </div>
                    <div className="mobBtnSignIn" onClick={closeModal}>
                      <Link href="/api/auth/signin">Sign In</Link>
                    </div>
                  </div>
                )}
              </div>
            </div>}
        </div>
      </nav>
    </article>
  );
}
