'use session'
import { DefaultSession } from "next-auth";
import { getSession } from "next-auth/react"


interface DefaultSessionNew {
  jwt: string | null,
  provider: string | null,
}



  export async function SearchApiWord(inputValue: string) {
    const inputValueEncoded = encodeURIComponent(inputValue);
    const url = `https://zvbot-dev.itd.pub/etymology/landing?word=${inputValueEncoded}`;
    const jwtToken = localStorage.getItem('jwt_token');
    const headers: HeadersInit | undefined = jwtToken ? {
      'Authorization': `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    } : undefined;
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });

    return await response.json();
  }


export async function SimilarApiWord(inputValue: string) {
  const inputValueEncoded = encodeURIComponent(inputValue);
  const similarUrl = `https://zvbot-dev.itd.pub/etymology/words/similar?word=${inputValueEncoded}`;
  const similarResponse = await fetch(similarUrl, {
    method: "GET",
  });
  return await similarResponse.json();
}

export async function PopularApiWord() {
  const popularUrl = `https://zvbot-dev.itd.pub/etymology/words/popular`;
  const popularResponse = await fetch(popularUrl, {
    method: "GET",
  });
  return await popularResponse.json();
}


export async function CategoryWord(category: string) {
  const categoryWordUrl = `https://zvbot-dev.itd.pub/etymology/category/popular?category=${category}`;
  const categoryWordResponse = await fetch(categoryWordUrl, {
    method: "GET",
  });
  return await categoryWordResponse.json();
}


export async function PopularWord() {
  const popularWordUrl = `https://zvbot-dev.itd.pub/etymology/words/popular`;
  const popularWordResponse = await fetch(popularWordUrl, {
    method: "GET",
  });
  return await popularWordResponse.json();
}


export async function UserActivityLike(id: number) {
  const userrActivityLike = `https://zvbot-dev.itd.pub/etymology/user_activity`;
  const jwtToken = localStorage.getItem('jwt_token');
  const headers = { 
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type' : 'application/json'
  };
  const responseUserrActivityLike = await fetch(userrActivityLike, {
    method: "POST",
    headers,
    body: JSON.stringify({
      "type_activity" : "like",
      "word_id": id,
    })
  });
  return await responseUserrActivityLike.json();
}


export async function UserActivityDislike(id: number) {
  const UserActivityDislike = `https://zvbot-dev.itd.pub/etymology/user_activity`;
  const jwtToken = localStorage.getItem('jwt_token');
  const headers = { 
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type' : 'application/json'
  };
  const responseUserrActivityDislike = await fetch(UserActivityDislike, {
    method: "POST",
    headers,
    body: JSON.stringify({
      "type_activity" : "dislike",
      "word_id": id,
    })
  });
  return await responseUserrActivityDislike.json();
}


export async function UserInfo() {
  const UserUrl = `https://zvbot-dev.itd.pub/etymology/user`;
  const jwtToken = localStorage.getItem('jwt_token');
  const headers = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  };
  const responseUser = await fetch(UserUrl, {
    method: "GET",
    headers,
  });

  if (responseUser.ok){
    console.log('Promise resolved and HTTP status is successful');
  }else{
    if (responseUser.status === 401){
      console.log("401 error");
      document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; 
      document.cookie = 'auth-next=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; 
        if (typeof window !== 'undefined'){
          localStorage.removeItem('jwt_token');
          localStorage.removeItem('jwt_token_cred');
      }
      location.reload();
    };
  }


  return await responseUser.json();
}


export async function UserActivityAll() {
  const jwtToken = localStorage.getItem('jwt_token');
  const UserActivityAll = `https://zvbot-dev.itd.pub/etymology/user_profile`;
  const headers = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } ;
  const responseUserActivityAll = await fetch(UserActivityAll, {
    method: "GET",
    headers
  });
  return await responseUserActivityAll.json();
}



export async function providerSignIn() {
  const sessionProvider:DefaultSession & DefaultSessionNew = await getSession() as DefaultSession & DefaultSessionNew;
  const urlProvider = `https://zvbot-dev.itd.pub/etymology/provider_auth?provider=${sessionProvider?.provider}`;
  const headers = { 
    'Authorization': `Bearer ${sessionProvider?.jwt}`,
    'Content-Type' : 'application/json'
  };
  const responseProvider = await fetch(urlProvider, {
    method: "GET",
    headers
  });

  const responseData = await responseProvider.json();
  const jwtToken: string = responseData.jwt_token;
  const email: string = responseData.email;
  localStorage.setItem('jwt_token', jwtToken);
  localStorage.setItem('email', email)
  return await responseProvider;
}



export async function UserPostComments(idWord: number, text: string) {
  const UserPostCommentsUrl = `https://zvbot-dev.itd.pub/etymology/add_comment`;
  const jwtToken = localStorage.getItem('jwt_token');

  const headers: HeadersInit | undefined = jwtToken ? {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } : undefined;
  
  const responseUserPostComments = await fetch(UserPostCommentsUrl, {
    method: "POST",
    headers,
    body: JSON.stringify({
      "word_id": idWord,
      "text" : text
    })
  });
  return await responseUserPostComments.json();
}


export async function CommentsReply(selectedCommentId: number, textReply: string, commentatorName: string) {
  const CommentsReplyUrl = `https://zvbot-dev.itd.pub/etymology/add_reply `;
  const jwtToken = localStorage.getItem('jwt_token');

  const headers: HeadersInit | undefined = jwtToken ? {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } : undefined;

  const responseCommentsReply = await fetch(CommentsReplyUrl, {
    method: "POST",
    headers,
    body: JSON.stringify({
      "comment_id": selectedCommentId,
      "replyTo_nickname": commentatorName,
      "text": textReply,
  })
  });
  return await responseCommentsReply.json();
}


export async function CommentsStatistic(id: number, type_activity: string, type_comment: string) {
  const CommentsStatisticUrl = `https://zvbot-dev.itd.pub/etymology/user_activity_comments`;
  const jwtToken = localStorage.getItem('jwt_token');

  const headers: HeadersInit | undefined = jwtToken ? {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } : undefined;

  const responseCommentsStatistic = await fetch(CommentsStatisticUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        type_activity: type_activity,
        type_comment: type_comment,
        comment_id: id,
      }),
    });
  return await responseCommentsStatistic.json();
}


export async function GetComments(idWord: number, limit: number, offset: number) {
  const GetCommentsUrl = `https://zvbot-dev.itd.pub/etymology/comments?word_id=${idWord}&limit=${limit}&offset=${offset}`;
  const jwtToken = localStorage.getItem('jwt_token');

  const headers: HeadersInit | undefined = jwtToken ? {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } : undefined;

  const responseGetComments = await fetch(GetCommentsUrl, {
    method: "GET",
    headers,
  });
  return await responseGetComments.json();
}


export async function GetReply(commentId: number, limit: number, offset: number) {
  const GetReplyUrl = `https://zvbot-dev.itd.pub/etymology/replies?comment_id=${commentId}&limit=${limit}&offset=${offset}`;
  const jwtToken = localStorage.getItem('jwt_token');

  const headers: HeadersInit | undefined = jwtToken ? {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } : undefined;

  const responseGetReply = await fetch(GetReplyUrl, {
    method: "GET",
    headers,
  });
  return await responseGetReply.json();
}


export async function CommentEdit(commentId: number, textEdit: string) {
  const jwtToken = localStorage.getItem('jwt_token');
  const UserActivityAll = `https://zvbot-dev.itd.pub/etymology/edit_comment`;
  const headers = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } ;
  const responseUserActivityAll = await fetch(UserActivityAll, {
    method: "POST",
    headers,
    body: JSON.stringify({
      "comment_id": commentId,
      "text": textEdit,
  })
  });
  return await responseUserActivityAll.json();
}

export async function ReplyEdit(replyId: number, textEdit: string) {
  const jwtToken = localStorage.getItem('jwt_token');
  const UserActivityAll = `https://zvbot-dev.itd.pub/etymology/edit_reply`;
  const headers = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } ;
  const responseUserActivityAll = await fetch(UserActivityAll, {
    method: "POST",
    headers,
    body: JSON.stringify({
      "reply_id": replyId,
      "text": textEdit,
  })
  });
  return await responseUserActivityAll.json();
}


export async function DeleteComment(commentID: number) {
  const jwtToken = localStorage.getItem('jwt_token');
  const DeleteComment = `https://zvbot-dev.itd.pub/etymology/delete_comment/${commentID}`;
  const headers = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } ;
  const responseDeleteComment = await fetch(DeleteComment, {
    method: "DELETE",
    headers,
  });
  return await responseDeleteComment.json();
}

export async function DeleteReply(ReplyID: number) {
  const jwtToken = localStorage.getItem('jwt_token');
  const DeleteReply = `https://zvbot-dev.itd.pub/etymology/delete_reply/${ReplyID}`;
  const headers = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  } ;
  const responseDeleteReply = await fetch(DeleteReply, {
    method: "DELETE",
    headers,
  });
  return await responseDeleteReply.json();
}


export async function SignUpApi(name: string, email: string, password: string) {
  const signUpUrl = `https://zvbot-dev.itd.pub/etymology/signup`;
  const headers = {
    'Content-Type': 'application/json'
  };

  const requestBody = JSON.stringify({
    name: name,
    email: email,
    password: password
  });

  const response = await fetch(signUpUrl, {
    method: 'POST',
    headers: headers,
    body: requestBody
  });

  return await response.json();
}


export async function SignInApi(email: string, password: string) {
  const signUpUrl = `https://zvbot-dev.itd.pub/etymology/auth`;
  const headers = {
    'Content-Type': 'application/json'
  };

  const requestBody = JSON.stringify({
    email: email,
    password: password
  });

  const response = await fetch(signUpUrl, {
    method: 'POST',
    headers: headers,
    body: requestBody
  });

  return await response.json();
}


export async function changeUserEmail(email: string) {
  const url = `https://zvbot-dev.itd.pub/etymology/user/change_email`;
  const jwtToken = localStorage.getItem('jwt_token');

  if (!jwtToken) {
    throw new Error('JWT token is not available');
  }

  const headers: HeadersInit = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify({
    new_email: email,
  });

  const response = await fetch(url, {
    method: "POST",
    headers,
    body,
  });

  if (!response.ok) {
    throw new Error(`Failed to change email: ${response.statusText}`);
  }

  return await response.json();
}


export async function changeUserPassword(password: string) {
  const url = `https://zvbot-dev.itd.pub/etymology/user/change_password`;
  const jwtToken = localStorage.getItem('jwt_token');

  if (!jwtToken) {
    throw new Error('JWT token is not available');
  }

  const headers: HeadersInit = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify({
    new_password: password,
  });

  const response = await fetch(url, {
    method: "POST",
    headers,
    body,
  });

  if (!response.ok) {
    throw new Error(`Failed to change email: ${response.statusText}`);
  }

  return await response.json();
}


export async function changeUserName(name: string) {
  const url = `https://zvbot-dev.itd.pub/etymology/user/change_name`;
  const jwtToken = localStorage.getItem('jwt_token');

  if (!jwtToken) {
    throw new Error('JWT token is not available');
  }

  const headers: HeadersInit = {
    'Authorization': `Bearer ${jwtToken}`,
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify({
    new_name: name,
  });

  const response = await fetch(url, {
    method: "POST",
    headers,
    body,
  });

  if (!response.ok) {
    throw new Error(`Failed to change email: ${response.statusText}`);
  }

  return await response.json();
}



export async function GetCategories(){
  const url = `https://zvbot-dev.itd.pub/etymology/categories`;

  const headers: HeadersInit = {
    'Content-Type': 'application/json'
  };

  const response = await fetch(url, {
    method: "GET",
    headers,
  });

  return await response.json();

}


export async function fetchCategoryWords(){
  const url = `https://zvbot-dev.itd.pub/etymology/by-categories`;

  const headers: HeadersInit = {
    'Content-Type': 'application/json'
  };

  const response = await fetch(url, {
    method: "GET",
    headers,
  });

  return await response.json();

}